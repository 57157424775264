<template>
	<div style="overflow-x:hidden;">
		<v-row style="padding:40px">
			<img :src="$store.state.icons.icons['favicon-32x32']" alt />
		</v-row>
		<v-row class="align-center justify-center" style="height:65vh">
			<v-col cols="11" sm="2" md="4"></v-col>
			<v-col cols="11" sm="8" md="4">
				<v-form v-model="valid" ref="form" lazy-validation>
					<v-card tag="div" elevation="0" color="#F7F9FC">
						<p class="text-sm-center my-heading">Reset Password</p>

						<v-row class="p-0" v-on:keydown.enter.prevent='forget'>
							<v-col cols="12" class="pb-0">
								<label>Email address</label>
							</v-col>
							<v-col cols="12" class="pt-1">
								<v-text-field
									single-line
									outlined
									dense
									:hide-details="Show"
									height="48"
									color="#CACEDA"
									type="text"
									placeholder="Email address"
									v-model="userdata.email"
									:rules="emailRules"
									required
								></v-text-field>
							</v-col>
						</v-row>

						<v-row class="align-center justify-center p-0 custom-error" :style="{'display':ShowMessage}">
							<v-col cols="12">{{this.error_message}}</v-col>
						</v-row>

						<v-row class="p-0">
							<v-col cols="12">
								<v-btn
									class="my-p-btn"
									id="my_elevation"
									depressed
									block
									@click="forget"
									:disabled="!valid"
								>Reset password</v-btn>
							</v-col>
						</v-row>
					</v-card>
				</v-form>
			</v-col>
			<v-col cols="11" sm="2" md="4"></v-col>
		</v-row>
	</div>
</template>


<script>
import { AXIOS } from "../../plugins/axios";
import Cookies from "js-cookie";

export default {
	data() {
		return {
			userdata: {
				email: ""
			},
			show: true,
			valid: true,
			emailRules: [
				v => !!v || "E-mail is required",
				v => /.+@.+/.test(v) || "E-mail must be valid"
			],
			error_message: "",
			show_message: "none"
		};
	},

	computed: {
		Show() {
			return (this.show = !this.valid ? false : true);
		},
		ShowMessage() {
			return (this.show_message = !this.error_message
				? "none"
				: "block");
		}
	},

	methods: {
		forget() {
			if (this.$refs.form.validate()) {
				AXIOS.post("auth/forget-password", this.userdata)
					.then(resp => {
						Cookies.set("email", this.userdata.email, {
							expires: 1
						});
						this.$router.push({ path: "/resetlink" });
					})
					.catch(error => {
						this.error_message = "";
						const response = error.response;
						this.error_message = response.data.message;
					});
			}
		}
	}
};
</script>

